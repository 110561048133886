import React from 'react'
import links from 'links'
import { useDevice } from 'device'
import { track } from 'analytics'
import { twcx } from 'helpers'
import { useEntryListener } from 'intersection-observer'

import { Image } from 'components/dataDisplay'
import { Href } from 'components/navigation'
import { AspectContainer, ScrollableContainer, WidthContainer } from 'components/layout'

import useBrandsImages from './util'


const BrandsRowAb: React.FunctionComponent = () => {
  const { isMobile } = useDevice()

  const brandsImages = useBrandsImages()

  const [ ref, unobserve ] = useEntryListener((entry) => {
    if (entry.isIntersecting) {
      track('Logos display')
      unobserve()
    }
  }, { once: true })

  const content = Object.entries(brandsImages).map(([ brand, image ]) => {
    const imageComponent = (
      <AspectContainer className="w-full" aspect={90 / 130} fit="contain">
        <Image
          fill
          src={image}
          alt={brand}
          remWidth={90}
        />
      </AspectContainer>
    )

    return brand === 'explore' ? (
      <Href key={brand} className="w-full" to={links.smartRecommendations}>
        {imageComponent}
      </Href>
    ) : (
      <figure key={brand} className="w-full" aria-label={`${brand} logo`}>
        {imageComponent}
      </figure>
    )
  })

  const containerClass = isMobile ? 'mt-16' : twcx('mt-32 grid', 'grid-cols-7 gap-80')

  return (
    <WidthContainer ref={ref} className={containerClass}>
      {
        isMobile ? (
          <ScrollableContainer>
            <div className="grid w-min auto-cols-[90rem] grid-flow-col gap-24">
              {content}
            </div>
          </ScrollableContainer>
        ) : content
      }
    </WidthContainer>
  )
}


export default BrandsRowAb
